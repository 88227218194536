import { useState } from "react";
import styles from "./ToggleSwitch.module.css";
import { updateMerchantWithPatchDataByMerchantUid } from "../../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";

const ToggleSwitch = ({ handleToggle, toggle, id, index, token }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchToggle = async () => {
    try {
      setLoading(true);
      const newValue = !toggle;

      const response = await updateMerchantWithPatchDataByMerchantUid(
        token,
        id,
        {
          canManageInventory: newValue,
        }
      );

      if (response?.code === "SUCCESS") {
        handleToggle(id, index, newValue);
      }
    } catch (error) {
      console.error("Failed to toggle merchant permission:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles["toggle-switch-container"]}>
      {loading && (
        <div className={styles.spinner}>
          <ClipLoader size={20} color="#000" />
        </div>
      )}
      <input
        className={styles["privacy-toggle"]}
        id={`toggle-for-checkbox-${index}`}
        type="checkbox"
        checked={toggle}
        onChange={handleSwitchToggle}
        disabled={loading}
      />
    </div>
  );
};

export default ToggleSwitch;
