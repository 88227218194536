import React, { useEffect, useState, useCallback } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

import {
  getMerchantUserDetails,
  getProductsAll,
  insertMerchantPostDataByMerchantUid,
  updateMerchantWithPatchDataByMerchantUid,
} from "../../env/APIManager";
import styles from "./MerchantUserDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import CRMNote from "../crm-notes/crmNote";
import ConfirmationModal from "../../../common/confirmation/ConfirmationModal";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
} from "@mui/material";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";

const ExpandableDiv = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const showStyle = {
    height: "auto",
  };
  const hideStyle = {
    paddingTop: "4px",
    paddingBottom: "4px",
  };

  return (
    <div
      style={isExpanded ? showStyle : hideStyle}
      className={styles.collapseSection}
      key={title}
    >
      <div onClick={handleToggle} className={styles.mainTitleContainer}>
        {isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
        <div className={styles.mainTitle}>{title}</div>
      </div>

      {isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
    </div>
  );
};

function MerchantUserDetails() {
  const [loading, setLoading] = useState(false);
  const [, setBtnLoading] = useState(false);
  const [priorityLevel, setPriorityLevel] = useState("");
  const [newPriorityLevel, setNewPriorityLevel] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [tempInventoryData, setTempInventoryData] = useState({});
  const [canManageInventory, setCanManageInventory] = useState(true);

  const [productRows, setProductRows] = useState([]); // State for storing API data
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [showTableModal, setShowTableModal] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null); // Tracks the item being edited
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const [token, setToken] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const key = params.get("key");
  const nextPageToken = params.get("nextPageToken");

  const handleGoBack = () => {
    if (nextPageToken && key) {
      history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
    } else if (key && key !== undefined && key !== null) {
      history.push(`/crm?key=${key}`);
    } else {
      history.push(`/crm`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const paginatedRows = productRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  let history = useHistory();

  const getUser = useCallback(
    async (token, id) => {
      setLoading(true);

      let data = await getMerchantUserDetails(token, id);

      if (data?.code === "SUCCESS") {
        setUserDetails(data?.data);
        setLoading(false);
        setPriorityLevel(data?.data?.priorityLevel || "");
        setCanManageInventory(
          typeof data?.data?.canManageInventory === "boolean"
            ? data?.data?.canManageInventory
            : true
        );
      } else if (data?.data?.code === "UNAUTHORIZED") {
        localStorage.clear();
        setLoading(false);
        toast.error(
          "You have been logged out. This may have been caused by using more than one device or browser"
        );
        history.push({ pathname: "/login" });
      } else {
        toast.error("Something went wrong, please try again later");
        setLoading(false);
      }
    },
    [history]
  ); // Only re-create when `history` changes

  function priorityLevelChange(e) {
    setNewPriorityLevel(e?.target?.value);
  }

  async function savePriorityLevel() {
    setLoading(true);
    let data = await updateMerchantWithPatchDataByMerchantUid(token, id, {
      priorityLevel: newPriorityLevel === "none" ? null : newPriorityLevel,
    });
    setNewPriorityLevel(null);
    handleServerResponse(data);
    setLoading(false);
    setPriorityLevel(newPriorityLevel);
  }

  // Token Retrieval and Setting
  useEffect(() => {
    let storedToken =
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === ""
        ? ""
        : JSON.parse(localStorage.getItem("token"));
    setToken(storedToken);
  }, []); // Runs only once when the component mounts

  // Routing Logic
  useEffect(() => {
    if (!id) {
      if (nextPageToken && key) {
        history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
      } else {
        history.push(`/crm?key=${key}`);
      }
    }
  }, [id, nextPageToken, key, history]); // Runs when id, nextPageToken, or key changes

  // User Data Fetching
  useEffect(() => {
    if (token && id) {
      getUser(token, id);
    }
  }, [token, id, getUser]);

  // Product Data Fetching
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const data = await getProductsAll(token);
        const productsAll = data.data.products;

        setProductRows(productsAll);
      } catch (error) {
        console.error("Failed to fetch product data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchProducts();
    }
  }, [token]);

  const toReadAbleDaysOfTheWeek = (day) => {
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "N/A";
    }
  };
  const from24hTo12hTime = (time) => {
    if (time) {
      if (Number(time) > 12) {
        return Number(time) - 12 + ":00 PM";
      } else {
        return Number(time) + ":00 AM";
      }
    } else {
      return "N/A";
    }
  };
  const addInventory = (e) => {
    e.preventDefault();
    setEditingItem(null);
    getProductsAll(token);
    setShowTableModal(true);
  };
  const handleChangeTempInventory = (e) => {
    const { name, value } = e.target;
    // Convert value to boolean if the name is "inStock"
    const parsedValue =
      name === "inStock"
        ? value === "true"
          ? true
          : value === "false"
          ? false
          : null
        : value;

    setTempInventoryData({
      ...tempInventoryData,
      [name]: parsedValue,
    });
  };
  const handleSaveNewInventory = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (!tempInventoryData) {
      toast.error("Please fill all the fields");
      return;
    }
    if (
      !tempInventoryData.skuId ||
      tempInventoryData?.skuId?.trim()?.length < 1
    ) {
      toast.error("Please fill skuId");
      return;
    }
    if (
      !tempInventoryData.productName ||
      tempInventoryData?.productName?.trim()?.length < 1
    ) {
      toast.error("Please fill productName");
      return;
    }
    if (!tempInventoryData.price) {
      toast.error("Please fill price");
      return;
    }
    if (
      tempInventoryData.inStock === null ||
      tempInventoryData.inStock === undefined
    ) {
      toast.error("Please fill inStock");
      return;
    }
    let postData = { inventory: tempInventoryData };
    let data = await insertMerchantPostDataByMerchantUid(token, id, postData);
    handleServerResponse(data);
  };
  const handleSaveEditInventory = async (e) => {
    e.preventDefault();

    setBtnLoading(true);
    if (!tempInventoryData) {
      toast.error("Please fill all the fields");
      return;
    }
    if (
      !tempInventoryData.productName ||
      tempInventoryData?.productName?.trim()?.length < 1
    ) {
      toast.error("Please fill productName");
      return;
    }
    if (!tempInventoryData.price) {
      toast.error("Please fill price");
      return;
    }
    if (
      tempInventoryData.inStock === null ||
      tempInventoryData.inStock === undefined
    ) {
      toast.error("Please fill inStock");
      return;
    }

    let patchData = { inventory: tempInventoryData };
    let data = await updateMerchantWithPatchDataByMerchantUid(
      token,
      id,
      patchData
    );
    handleServerResponse(data);
  };
  const handleEdit = (item) => {
    setEditingItem(item);
    setTempInventoryData({
      skuId: item.skuId,
      productName: item.productName,
      price: item.price,
      inStock: item.inStock,
    });
    setShowInputModal(true);
  };
  const handleSkuDetails = (item) => {
    const productData = productRows.find(
      (product) => product.id === item.skuId
    );
    setEditingItem(item);
    console.log(productData);
    setTempInventoryData({
      ...tempInventoryData,
      image: productData?.img,
      title: productData?.title,
      maxPrice: productData?.maxPrice,
      merchantProductInformation: productData?.merchantProductInformation,
    });
    setShowProductDetailsModal(true);
  };

  function handleServerResponse(data) {
    if (data?.code === "SUCCESS") {
      setShowInputModal(false);
      setBtnLoading(false);
      toast.success("Inventory added successfully");
      setTempInventoryData({});
      setUserDetails({ ...userDetails, inventory: data.data.inventory });
    } else if (data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setBtnLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      setBtnLoading(false);
      toast.error("Something went wrong, please try again later");
    }
  }

  return (
    <>
      <ToastContainer limit={1} />
      <div className={styles.mainContainer}>
        {loading ? (
          <div className={styles.clipLoader}>
            <ClipLoader color="#000" size={50} />
          </div>
        ) : userDetails && Object.keys(userDetails)?.length > 0 ? (
          <>
            <Modal
              show={showTableModal}
              onHide={() => setShowTableModal(false)}
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Products</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="Table of Products">
                    <TableHead>
                      <TableRow>
                        <TableCell>SKU ID</TableCell>
                        <TableCell align="right">Image</TableCell>
                        <TableCell align="right">Title</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Max Price</TableCell>
                        <TableCell align="right">
                          Merchant Product Information
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover
                          style={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                              "&:active": {
                                backgroundColor: "#e0e0e0",
                              },
                            },
                          }}
                          onClick={() => {
                            setShowTableModal(false);
                            setTempInventoryData({
                              ...tempInventoryData,
                              skuId: row.id,
                              productName: row.title,
                              price: row.price,
                            });
                            setShowInputModal(true);
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="right">
                            <img
                              src={row.img}
                              alt={row.title || "Product Image"}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">{row.title}</TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                          <TableCell align="right">{row.maxPrice}</TableCell>
                          <TableCell align="right">
                            {row.merchantProductInformation}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
                  component="div"
                  count={productRows.length} // Total number of rows
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className={styles.modalButtonsContainer}>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => setShowTableModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showInputModal}
              onHide={() => setShowInputModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {editingItem ? "Edit Inventory" : "Add Inventory"}
                  {/* Dynamic Title */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>SKUID</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="text"
                        name="skuId"
                        readOnly
                        value={tempInventoryData.skuId}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>Product Name</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="text"
                        name="productName"
                        onChange={handleChangeTempInventory}
                        value={tempInventoryData.productName}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>Price</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="number"
                        name="price"
                        onChange={handleChangeTempInventory}
                        value={tempInventoryData.price}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>In Stock?</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <select
                        type="select"
                        name="inStock"
                        onChange={handleChangeTempInventory}
                        className={styles.modalInput}
                        value={tempInventoryData.inStock}
                      >
                        <option value="">Please select</option>
                        <option value={true}>In Stock</option>
                        <option value={false}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className={styles.modalButtonsContainer}>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => setShowInputModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className={styles.confirmBtn}
                    onClick={
                      editingItem
                        ? (e) => handleSaveEditInventory(e)
                        : (e) => handleSaveNewInventory(e)
                    }
                  >
                    Save
                  </button>
                </div>
                <div className={styles.getSkuDetailsBtnContainer}>
                  {editingItem && (
                    <button
                      className={styles.getSkuDetailsBtn}
                      onClick={() => {
                        setShowInputModal(false);
                        handleSkuDetails(editingItem);
                      }}
                    >
                      Get SKU Details from Database
                    </button>
                  )}
                </div>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showProductDetailsModal}
              onHide={() => setShowProductDetailsModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>SKU Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className={styles.modalHeader}>
                    <div
                      id="sku-id-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>SKUID</h6>
                      <p className={styles.modalBodyText}>
                        {tempInventoryData.skuId}
                      </p>
                    </div>

                    <div
                      id="product-image-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>Product Image:</h6>
                      <img
                        id="product-image"
                        src={tempInventoryData.image}
                        alt={tempInventoryData.title || "Product Image"}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <div
                      id="merchant-product-info-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>
                        Merchant Product Information:
                      </h6>
                      <p className="product-info">
                        {tempInventoryData.merchantProductInformation}
                      </p>
                    </div>

                    <div
                      id="product-max-price-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>Max Price:</h6>
                      <p className="product-info">
                        {tempInventoryData.maxPrice}
                      </p>
                    </div>

                    <div
                      id="product-name-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>Product Name</h6>
                      <p className={styles.modalBodyText}>
                        {tempInventoryData.productName}
                      </p>
                    </div>

                    <div
                      id="product-price-container"
                      className="product-detail-container"
                    >
                      <h6 className={styles.modalTitle}>Price</h6>
                      <p className={styles.modalBodyText}>
                        {tempInventoryData.price}
                      </p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className={styles.modalButtonsContainer}>
                  <button
                    className={styles.prefillBtn}
                    onClick={() => {
                      setTempInventoryData({
                        ...tempInventoryData,
                        productName:
                          tempInventoryData.merchantProductInformation,
                      });
                      setShowProductDetailsModal(false);
                      setShowInputModal(true);
                    }}
                  >
                    Prefill Product Name
                  </button>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => {
                      setShowProductDetailsModal(false);
                      setShowInputModal(true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

            <div className={styles.headerContainer}>
              <ArrowBackIcon
                fontSize="large"
                style={{ marginLeft: "-5px", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              <div className={styles.titleContainer}>
                <div className={styles.pageTitle}>
                  User #{id?.substring(id?.length - 5)}{" "}
                </div>
              </div>
              <ConfirmationModal
                title={`Are you sure you want to update priority level to ${newPriorityLevel}?`}
                onConfirm={savePriorityLevel}
                show={!!newPriorityLevel}
                onCancel={() => setNewPriorityLevel(null)}
              ></ConfirmationModal>
              <div className={styles.headerOptions}>
                <div className={styles.inlineInput}>
                  <label>Priority Level:</label>
                  <select
                    type="select"
                    value={priorityLevel}
                    onChange={priorityLevelChange}
                  >
                    <option value={"none"}>None</option>
                    <option value={1}>Level 1</option>
                    <option value={2}>Level 2</option>
                    <option value={3}>Level 3</option>
                  </select>
                </div>
              </div>
            </div>
            {/* user information section */}
            <ExpandableDiv title="User Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyContainer}>
                    <div className={styles.bodyTitle}>Created</div>
                    <div>
                      {userDetails?.createdAt
                        ? new Date(userDetails?.createdAt).toLocaleDateString(
                            "en-US"
                          ) +
                          " " +
                          new Date(userDetails?.createdAt).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : "N/A"}
                    </div>
                  </div>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>First Name</div>
                      <div>
                        {userDetails?.firstName
                          ? userDetails?.firstName
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Last Name</div>
                      <div>
                        {userDetails?.lastName ? userDetails?.lastName : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Business Name</div>
                      <div>
                        {userDetails?.businessName
                          ? userDetails?.businessName
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Phone Number</div>
                      <div>
                        {userDetails?.phoneNumber
                          ? userDetails?.phoneNumber
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Email</div>
                      <div>
                        {userDetails?.email ? userDetails?.email : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        Allow Inventory Management
                      </div>
                      <div className={styles.inlineInput}>
                        <ToggleSwitch
                          label="Allow Inventory Management"
                          handleToggle={(_id, _index, newValue) => {
                            setCanManageInventory(newValue);
                          }}
                          toggle={canManageInventory}
                          id={id}
                          index={0}
                          token={token}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        Payment Setup Complete
                      </div>
                      <div>{userDetails?.paymentsSetup ? "Yes" : "No"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpandableDiv>
            <ExpandableDiv title="Business & Special Hours">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyTitle}>Business Hours</div>
                  {userDetails?.business_hours ? (
                    userDetails?.business_hours.map((item, index) => {
                      return (
                        <div key={index} className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainerHours}>
                            <div className={styles.hoursContainer}>
                              <>{toReadAbleDaysOfTheWeek(item.dayOfWeekId)}</>
                              {item.openTime ? (
                                <div>
                                  {from24hTo12hTime(item.openTime)}-
                                  {from24hTo12hTime(item.closeTime)}
                                </div>
                              ) : (
                                <div>
                                  {item.closedAllDay
                                    ? "Closed"
                                    : item.openAllDay
                                    ? "Open All Day"
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainerHours}>
                        <div className={styles.bodyTitle}>
                          No Business Hours
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyTitle}>Special Hours</div>
                  {userDetails?.special_hours ? (
                    userDetails?.special_hours.map((item, index) => {
                      return (
                        <div key={index} className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainerHours}>
                            <div className={styles.hoursContainer}>
                              {new Date(item.date).toUTCString().slice(0, 16)}
                              {item.openTime ? (
                                <div>
                                  {from24hTo12hTime(item.openTime)}-
                                  {from24hTo12hTime(item.closeTime)}
                                </div>
                              ) : (
                                <div>
                                  {item.closedAllDay
                                    ? "Closed"
                                    : item.openAllDay
                                    ? "Open All Day"
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainerHours}>
                        <div className={styles.bodyTitle}>No Special Hours</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ExpandableDiv>
            <ExpandableDiv title="Inventory">
              <div className={styles.containerInventory}>
                <div className={styles.containerButtonInventory}>
                  <button className={styles.addBtn} onClick={addInventory}>
                    Add
                  </button>
                </div>
                {userDetails?.inventory &&
                Object.values(userDetails?.inventory)?.length > 0 ? (
                  <>
                    <div className={styles.contentContainerTitle}>
                      <div className={styles.bodyTitleInventory}>Inventory</div>
                    </div>
                    <div className={styles.contentContainerInventory}>
                      <div className={styles.bodyTitleInventory}>SKU ID</div>
                      <div className={styles.bodyTitleInventory}>Product</div>
                      <div className={styles.bodyTitleInventory}>Price</div>
                      <div className={styles.bodyTitleInventory}>In Stock?</div>
                      <div className={styles.bodyTitleInventory}>
                        Edit Product
                      </div>
                    </div>
                    {userDetails?.inventory &&
                      Object.values(userDetails?.inventory)?.map(
                        (item, index) => {
                          return (
                            <div
                              className={styles.contentContainerInventory}
                              key={index}
                            >
                              <div className={styles.hoursContainer}>
                                <div className={styles.bodyText}>
                                  {item.skuId}
                                </div>
                                <div className={styles.bodyText}>
                                  {item.productName}
                                </div>
                                <div className={styles.bodyText}>
                                  ${item.price}
                                </div>
                                <div className={styles.bodyText}>
                                  {item.inStock ? "In Stock" : "Out of Stock"}
                                </div>
                                <div className={styles.bodyText}>
                                  <button
                                    className={styles.editBtn}
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    <div className={styles.contentContainerInventory}></div>
                  </>
                ) : (
                  <>
                    <div className={styles.contentContainerNoInventory}>
                      <div className={styles.bodyTitleNoInventory}>
                        No Inventory
                      </div>
                    </div>
                    <div className={styles.contentContainerNoInventory}></div>
                  </>
                )}
              </div>
            </ExpandableDiv>
            <CRMNote id={id} />
            {/* <UserOrders id={id} /> */}
          </>
        ) : (
          <div className={styles.headerContainer}>
            <ArrowBackIcon
              fontSize="large"
              style={{ marginLeft: "-5px", cursor: "pointer" }}
              onClick={handleGoBack}
            />
            <div className={styles.titleContainer}>
              <div className={styles.pageTitle}>
                No information is available
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MerchantUserDetails;
